import { Meeting } from "./Meeting"

class Minutes {
  title: string
  date: string
  location: string
  users: string
  files: string
  type: number

  constructor(input?: Minutes) {
    this.title = input?.title || ""
    this.date = input?.date || ""
    this.location = input?.location || ""
    this.users = input?.users || ""
    this.files = input?.files || ""
    this.type = input?.type || 0
  }

  setFromMeeting(input: Meeting) {
    this.title = input?.subject || ""
    this.date = input?.startTime.toDateJpStringFull() || ""
    this.location = input?.location || ""
    this.users = input?.users.map(u => u.displayName).join(", ") || ""
    this.files = input?.files.map(u => u.name).join(", ") || ""
    this.type = 0

    return this
  }
}

export default Minutes
