






























import { computed, defineComponent, ref } from "@vue/composition-api"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import TextField from "@/components/textFields/TextField.vue"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Minutes from "@/models/Minutes"

export const exportType = {
  Pdf: 0,
  Word: 1,
}

export default defineComponent({
  props: {
    value: {
      type: Minutes,
      required: true,
    },
    canSelectType: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    I18nFormattedMessage,
    DefaultDialog,
    UsersAutoComplete,
    TextArea,
    TextField,
  },
  setup(props, { emit }) {
    const minutesData = ref<Minutes>(new Minutes(props.value))
    const minutesTitle = computed({
      get: () => props.value.title,
      set: newValue => {
        minutesData.value.title = newValue
        emit("input", minutesData.value)
      },
    })
    const minutesDate = computed({
      get: () => props.value.date,
      set: newValue => {
        minutesData.value.date = newValue
        emit("input", minutesData.value)
      },
    })
    const minutesLocation = computed({
      get: () => props.value.location,
      set: newValue => {
        minutesData.value.location = newValue
        emit("input", minutesData.value)
      },
    })
    const minutesUsers = computed({
      get: () => props.value.users,
      set: newValue => {
        minutesData.value.users = newValue
        emit("input", minutesData.value)
      },
    })
    const minutesFiles = computed({
      get: () => props.value.files,
      set: newValue => {
        minutesData.value.files = newValue
        emit("input", minutesData.value)
      },
    })
    const outputType = computed({
      get: () => props.value.type,
      set: newValue => {
        minutesData.value.type = newValue
        emit("input", minutesData.value)
      },
    })
    return {
      minutesTitle,
      minutesDate,
      minutesLocation,
      minutesUsers,
      minutesFiles,
      outputType,
      exportType,
    }
  },
})
